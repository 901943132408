import React from 'react'

import Layout from '../../components/Layout'
import BlogRoll from '../../components/BlogRoll'

export default class BlogIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <section id='hero' className='hero is-dark-brown'>
          <div className='hero-body'>
            <div className='container'>
              <div className='section'>
                <h1 className='title is-1 has-text-white'>Blog</h1>
              </div>
            </div>
          </div>
        </section>
        <section className='section'>
          <div className='container'>
            <BlogRoll />
          </div>
        </section>
      </Layout>
    )
  }
}
